import React from "react"
import Layout from "../components/layout"
import SEO from "../components/SEO"
import {graphql, useStaticQuery} from "gatsby";
import BlockContent from '@sanity/block-content-to-react';
import {center, left, right} from "../marks/alignment";

export default ({ location }) => {
    const { sanityAbout } = useStaticQuery(graphql`
        query {
            sanityAbout {
                pageTitle
                _rawContent
            }
        }
    `);
    const aboutContent = sanityAbout;

    return (
        <Layout location={location}>
            <SEO title={aboutContent.pageTitle}/>
            <h1 className={"text-lg page-label mb-4"}>{aboutContent.pageTitle}</h1>
            <hr className={"border-gray-800 mb-4"}/>
            <div className={"flex justify-between"}>
                <BlockContent blocks={aboutContent._rawContent} serializers={{marks: {left, center, right}}}/>
            </div>
        </Layout>
    )
}
